import React from 'react'

export default function TextComponent({ title }) {
  return (
    <div className="flex flex-row space-x-4 items-center">
      <div className="flex-shrink-0 bg-[#082552] h-3.5 w-3.5 rounded-[3px] shadow-custom"></div>
      <p className="text-neutralGrey font-normal text-xl leading-7">{title}</p>
    </div>
  )
}
