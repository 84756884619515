import React, { useState } from "react";

export default function FAQComponent({ title, subTitle }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-row justify-between px-6 py-7 border-b-[1px] border-navyBlue">
      <div className="flex flex-col space-y-7">
        <p className="font-semibold text-xl md:text-2xl text-navyBlue">{title}</p>
        {open && (
          <p className="text-xl font-normal text-navyBlue">{subTitle}</p>
        )}
      </div>
      <div
        className="cursor-pointer flex h-11 w-11 flex-shrink-0 rounded-full justify-center items-center bg-custom-grey"
        onClick={() => setOpen(!open)}
      >
        {open ? (
          <svg
            width="17"
            height="2"
            viewBox="0 0 17 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 1H15.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 1V15M1.5 8H15.5"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
}
