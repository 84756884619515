import React from "react";
import { LuHeartHandshake } from "react-icons/lu";
import { CiSquarePlus } from "react-icons/ci";
import { useLocation } from "react-router-dom";

function TitleHeader({ click }) {
  const router = useLocation();
  return (
    <div className="px-[2rem] py-[2rem] bg-white flex justify-between w-full">
      <div className="flex items-center">
        <LuHeartHandshake className="w-[2rem] text-[#FC5059]  h-[2rem]" />
        <h4 className="ml-[1rem]">Community</h4>
      </div>
      {(router?.pathname === "/dashboard/community/feeds" ||
        router?.pathname === "/dashboard/community/communities") && (
        <div
          onClick={click}
          className="flex items-center border border-[#02023414] rounded-[6px] px-[.5rem]"
        >
          <CiSquarePlus className="w-[16px] text-[#000000]  h-[16px]" />

          <button className="ml-[.2rem]">New community</button>
        </div>
      )}
    </div>
  );
}

export default TitleHeader;
