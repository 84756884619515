import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

function LeaveCommunity({ open, onClose, userLeave }) {
  return (
    <Modal className="w-full " isOpen={open} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="text-left px-[1rem] py-[1rem] mt-[2rem]">
            <h4 className="font-Eina01-Semibold text-[#27272e] font-[400]">
              Leave Ipaja Ayobo Youth Community?
            </h4>
            <p className="mb-[2rem] text-[#60646c] font-[400] font-Eina01-Regular">
              Once you leave this community you will lose access to all post,
              media, community by community member until you join again.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={onClose}
                className="shadow-customLight rounded-[5px] px-2 py-1"
              >
                No, Cancel
              </button>
              <button
                onClick={() => userLeave()}
                className="bg-[#E51C00] text-white rounded-[5px] px-2 py-1"
              >
                Yes, Leave
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LeaveCommunity;
