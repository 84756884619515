import React from "react";
import AlarmIcon from "../../assets/images/alarm.png";

function EmptyState({ click }) {
  return (
    <div className="text-center my-[2rem]">
      <div className="bg-[#f9c6c6] mx-auto w-[44px] p-[10px] h-[44px] rounded-[50%]">
        <img
          src={AlarmIcon}
          alt="icon"
          className="object-contain w-[24px] h-[24px] p-1"
        />
      </div>
      <p className="text-[#60646c]">Not part of a community Yet</p>
      <p className="text-[#60646c] text-[14px] w-[50%] mx-auto text-center">
        Search and join communities around you, ask for tips, directions, find
        professionals and so much more.
      </p>
      <div className="my-[1rem]">
        <button className="bg-[#f9c6c6] mr-[1rem] px-[1rem] py-[.5rem] rounded-[10px] text-[#641723] text-[14px] ">
          Find a community
        </button>
        <button
          onClick={click}
          className=" text-[#641723] px-[1rem] py-[.5rem] rounded-[10px] text-[14px] border-[1px] border-[#641723]"
        >
          Create new community
        </button>
      </div>
      <p className="text-[#60646c]">Have an invitation?</p>
    </div>
  );
}

export default EmptyState;
