import React, { useCallback, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { SlArrowLeft } from "react-icons/sl";
import LockIcon from "../../assets/images/lock-circle.svg";
import Avatar from "../../assets/images/Avatars.svg";
import Empty from "../../assets/images/Illustration.png";
import { FiPlus } from "react-icons/fi";
import CommunityPostCard from "./CommunityPostCard";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import TitleHeader from "./TitleHeader";
import { useState } from "react";
import {
  communityById,
  communityPost,
  inviteCommunity,
  leaveCommuniti,
} from "../../redux/api";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import InviteCommunityModal from "./InviteCommunityModal";
import { useDisclosure } from "@chakra-ui/react";
import { setModalStatus } from "../../redux/auth/auth.actions";
import { toast } from "react-toastify";

import CommunityInfo from "./CommunityInfo";
import LeaveCommunity from "./LeaveCommunity";
import Loader from "../Loader";

function ViewCommunity() {
  const theme = useSelector((state) => state.features.settings.darktheme);
  const token = useSelector((state) => state.auth.accessToken);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const [, setLoadingMore] = useState(false);
  const [, setLoading] = useState(false);
  const [community, setCommunity] = useState({});
  const [events, setEvents] = useState([]);
  const [, setIndex] = useState(2);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState("");
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const [trigger, setTrigger] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });
  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  const handleDropDown = () => {
    setOpen(!open);
  };

  const getCommunities = useCallback(
    async (more) => {
      setLoading(true);
      try {
        // more ? setLoadingMore(true) : setLoading(true);
        const { data } = await communityById(id);
        if (data) {
          setCommunity(data?.data);
          setLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoading(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    []
  );

  const getCommunityEvents = useCallback(
    async (more) => {
      setLoading(true);
      try {
        // more ? setLoadingMore(true) : setLoading(true);
        const { data } = await communityPost(id);
        if (data) {
          setEvents(data?.data?.communities);
          setLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoading(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    getCommunityEvents();
  }, [id]);

  const inviteMemberCommunity = () => {
    onOpen();
    dispatch(setModalStatus(73));
    // await submit(code);
  };

  const CloseCommunityModal = () => {
    onClose();
    dispatch(setModalStatus(0));
  };

  const sendInvite = async (payload) => {
    try {
      const { data } = await inviteCommunity(id, payload);
      if (data) {
        toast.success(data?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const leaveCommunityGroup = async () => {
    try {
      const { data } = await leaveCommuniti(id);
      if (data) {
        toast.success(data?.message);
        CloseCommunityModal();

        navigate("/dashboard/community/communities");
      }
    } catch (error) {
      setLoading(false);
      CloseCommunityModal();
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });

                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  const viewInfo = (id) => {
    setOpen(false);
    onOpen();
    dispatch(setModalStatus(74));
  };

  const leaveComm = (id) => {
    setOpen(false);
    onOpen();
    dispatch(setModalStatus(75));
  };
  return (
    <DashboardLayout>
      {community === {} ? (
        <Loader />
      ) : (
        <div className="h-auto pb-[8rem] bg-white w-full">
          <TitleHeader />
          <div className="max-w-[600px] mx-auto  pt-[rem]">
            <div className="flex mb-[1.5rem]">
              <SlArrowLeft
                onClick={() => navigate(-1)}
                className="bg-[#f2f4f7] rounded-[50%] w-[36px] h-[36px] p-[.8rem]"
              />
              <h4 className="font-[590] mx-auto text-center text-[20px]">
                {community?.communityName}
              </h4>
              <div className="relative ">
                <div onClick={handleDropDown} className="cursor-pointer">
                  <HiOutlineDotsHorizontal className="bg-[#f2f4f7] rounded-[50%] w-[36px] h-[36px] p-[.8rem]" />
                </div>
                {open && (
                  <ul
                    className={`absolute -bottom-[10rem] shadow-md rounded-[5px] cursor-pointer w-[190px] -right-[1rem] px-[.5rem] py-4 pb-[1rem] mx-auto  text-[.8rem] 
                ${theme ? "bg-black text-white" : "bg-[#fff] text-kdark3"}`}
                  >
                    <li
                      onClick={() => viewInfo(id)}
                      className="mb-[.4rem]  hover:rounded-[5px]  hover:bg-[#F2F4F7] py-[.5rem]  px-[1rem]"
                    >
                      Community info
                    </li>
                    <li
                      // onClick={() => updatePostStatus("comment")}
                      className="mb-[.4rem] hover:bg-[#F2F4F7]  hover:rounded-[5px] py-[.5rem]  px-[1rem]"
                    >
                      Report community
                    </li>
                    <li
                      onClick={() => leaveComm()}
                      className="mb-[.4rem] hover:bg-[#F2F4F7] hover:rounded-[5px] py-[.5rem]  px-[1rem]"
                    >
                      Leave community
                    </li>
                  </ul>
                )}
              </div>
            </div>

            <div
              className={`flex items-center w-full h-[50px] mb-[1rem] px-5 ${
                darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
              } rounded-[43px]`}
            >
              <input
                placeholder="Search Community"
                className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
              />
              <CiSearch size={24} />
            </div>
            <div className="w-full bg-black h-[175px] rounded-[4px]">
              {community?.communityCoverPhoto && (
                <img
                  src={community?.communityCoverPhoto}
                  alt="cover"
                  className="w-full object-fill h-full"
                />
              )}
            </div>
            <div className="flex justify-between w-full mt-[.8rem]">
              <div className="flex w-full items-center">
                <h4 className="text-[16px] text-black font-[400] mr-[1rem]">
                  {community?.communityName}
                </h4>
                <img src={LockIcon} alt="icon" className="w-[20px] h-[20px]" />
              </div>
              <div>
                <button
                  onClick={inviteMemberCommunity}
                  className="border-[1.5px] flex items-center font-[400] text-[#E5484D] border-[#E5484D] rounded-[25px] px-[1rem] py-[.3rem]"
                >
                  <FiPlus className="text-[16px] mr-[.3rem]" /> Invite
                </button>
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <img src={Avatar} alt="user icon" />
                <p className="text-[12px] font-[400] ml-[.5rem]">
                  {" "}
                  {community?.memberCount} Members
                </p>
              </div>
              <p className="text-[14px] text-black mt-[.5rem]">
                {community?.communityDesc}
              </p>
            </div>
            <div className="my-[1rem] border-b-[#E5484D] border-b-[2px] text-center bg-[#05055806] py-[.5rem]">
              <h4>All Posts</h4>
            </div>
            <div>
              {events?.length > 0 &&
                events?.map((item) => (
                  <CommunityPostCard
                    data={item}
                    coords={currentLocation}
                    token={token}
                  />
                ))}
              {events?.length === 0 && (
                <div className="text-center">
                  <img src={Empty} className="mx-auto" />
                  <h4 className="text-center font-[500] font-Eina01 Semibold">
                    No posts yet
                  </h4>
                  <p>Post made by member will show up here.</p>

                  <button className="bg-[#E5484D] rounded-[10px] py-[.5rem]  mt-2 font-Eina01Semibold text-white px-[1.5rem]">
                    Post
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="bg-[#E5484D] w-[35.29px] h-[35.29px] rounded-[50%] text-white text-[1rem] fixed bottom-16 left-[1061.35px] text-center flex items-center justify-center">
          <FiPlus className="text-[16px] text-white font-bold" />
        </div>
      </div>
      {uploadModalStatus === 73 && (
        <InviteCommunityModal
          id={selectedCommunityId}
          open={uploadModalStatus === 73}
          type={"My"}
          onClose={CloseCommunityModal}
          submit={sendInvite}
        />
      )}

      {uploadModalStatus === 74 && (
        <CommunityInfo
          open={uploadModalStatus === 74}
          onClose={CloseCommunityModal}
          type={"Edit"}
          community={community}
          invite={inviteMemberCommunity}
          userLeave={leaveCommunityGroup}
        />
      )}
      {uploadModalStatus === 75 && (
        <LeaveCommunity
          open={uploadModalStatus === 75}
          onClose={CloseCommunityModal}
          type={"Edit"}
          submit={sendInvite}
          userLeave={leaveCommunityGroup}
        />
      )}
    </DashboardLayout>
  );
}

export default ViewCommunity;
