import React, { useState } from "react";
import TextButton from "../../components/Button/TextButton";
import {
  aradAmbassador,
  aradAmbassador2,
  aradAmbassador3,
} from "../../constants/images";
import GeneralHeader from "../../components/GeneralHeader/GeneralHeader";
import GeneralFooter from "../../components/GeneralFooter/GeneralFooter";
import TextComponent from "../../components/TextComponent/TextComponent";
import AmbassadorCard from "../../components/Card/AmbassadorCard";
import FAQComponent from "../../components/TextComponent/FAQComponent";
import { AmbassadorProgramModal } from "../../components/Modal/AmbassadorProgramModal";

const Ambassador = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <section
        style={{ backgroundColor: "rgba(255, 240, 240, 1)" }}
        className={`relative h-screen/2 flex flex-col`}
      >
        <div className="z-20">
          <GeneralHeader mode={"dark"}/>
        </div>
        {isOpen && <AmbassadorProgramModal 
          closeModal={() => {
            setIsOpen(false);
          }} />
        }

        <div className="flex flex-col items-center flex-1">
          <div className="absolute inset-0 opacity-50"></div>
          <div className="relative z-10">
            <div className="flex flex-col items-center md:mt-40 mt-20 mb-[86px]">
              <div className="text-center">
                <p
                  className={`font-Eina01-Bold font-semibold text-4xl md:px-0 px-8 md:text-6xl text-neutralDark`}
                >
                  Be the change in <br/>
                  your community
                </p>
              </div>

              <div className="font-normal text-center w-3/5 mt-6 mb-10">
                <p
                  className={`font-Eina01-Regular text-neutralGrey text-sm font-normal leading-5`}
                >
                  Join the ARAD Ambassador Program and help make your community safer, stronger, and more connected.
                </p>
              </div>

              <TextButton
                title={"Apply Now"}
                onClick={() => {
                  setIsOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full max-h-[682px]">
          <img
            src={aradAmbassador}
            alt="ambassador1"
            className="object-cover w-full md:h-[682px] h-full"
          />
      </section>

      <section className="py-12 md:py-[151px] px-4 md:px-32">
        <div className="flex flex-col wdx:flex-row justify-between md:space-x-36 space-y-8 md:space-y-0 items-center">
          <div className="flex flex-col space-y-6 flex-1">
            <p className="text-neutralDark text-3xl md:text-5xl font-semibold">What is the ARAD <br/> Ambassador Program?</p>
            <p className="text-neutralGrey font-normal text-xl leading-7">
              As an ARAD Community Ambassador, you'll play a key role in connecting people in your local community, sharing vital information, and promoting growth. With ARAD, you have the tools to create online communities, share real-time safety updates, and foster collaboration like never before.
            </p>
            <p className="text-neutralGrey font-normal text-xl leading-7">Key Roles:</p>
            <div className="flex flex-col space-y-5">
              <TextComponent title={"Create and grow online communities for your neighborhood."}/>
              <TextComponent title={"Promote safety by sharing real-time alerts."}/>
              <TextComponent title={"Organize and promote local events."}/>
              <TextComponent title={"Support local businesses and connect them to residents."}/>
            </div>
          </div>

          <div className="bg-[#FFF0F0] rounded-2xl">
            <img
              src={aradAmbassador2}
              alt="aradAmbassador2"
              className="object-contain max-h-[600px]"
            />
          </div>
        </div>
      </section>

      <section className="pt-12 md:py-[72px] px-4 md:px-60 bg-[#E5484D]">
        <div className="flex flex-col">
          <p className="font-semibold text-3xl md:text-5xl text-white mt-10 mb-16 md:mt-20 md:mb-36 text-center">Why Join the ARAD Ambassador Program?</p>
          <div class="grid md:grid-cols-2 grid-cols-1 gap-9 pb-20">
            <AmbassadorCard
              icon={<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.66602 21.4492C3.35685 21.4492 5.71805 20.9474 7.19841 22.0322L10.1358 24.1846C11.2271 24.9843 12.4091 24.7209 13.6268 24.5297C15.2309 24.2778 16.666 25.6459 16.666 27.4269C16.666 27.9137 13.2114 29.1508 12.712 29.386C11.7311 29.8481 10.6043 29.7343 9.71637 29.0837L6.40286 26.6557" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.666 26.3333L23.4843 23.1826C24.7067 22.7566 26.0544 23.0892 26.9656 24.0417L28.0835 25.2104C28.483 25.6281 28.3891 26.3294 27.8951 26.6162L14.8042 34.2191C14.0155 34.6772 13.0851 34.7889 12.2176 34.5298L1.66602 31.3782" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0026 18C15.0026 18 18.5026 14.269 18.5026 9.66666C18.5026 5.06429 15.0026 1.33333 15.0026 1.33333C15.0026 1.33333 11.5026 5.06429 11.5026 9.66666C11.5026 14.269 15.0026 18 15.0026 18ZM15.0026 18C15.0026 18 20.1143 17.886 23.3359 14.5986C26.5576 11.3112 26.6693 6.09523 26.6693 6.09523C26.6693 6.09523 24.5535 6.1424 22.1865 7.05144M15.0026 18C15.0026 18 9.89093 17.886 6.66927 14.5986C3.44761 11.3112 3.33594 6.09523 3.33594 6.09523C3.33594 6.09523 5.45169 6.1424 7.81873 7.05144" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>}
              title={"Make a Difference"}
              subTitle={"Lead your community toward growth and collaboration."}
            />
            <AmbassadorCard
               icon={<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.88 3.7403L23.8131 9.65479C24.213 10.4781 25.2796 11.2678 26.1795 11.4191L31.4955 12.3096C34.8952 12.8809 35.6951 15.3677 33.2453 17.8208L29.1125 21.9879C28.4125 22.6936 28.0293 24.0546 28.2459 25.0291L29.4291 30.1875C30.3623 34.2705 28.2126 35.85 24.6296 33.716L19.6468 30.742C18.7469 30.2043 17.2638 30.2043 16.3472 30.742L11.3644 33.716C7.79816 35.85 5.63173 34.2537 6.56496 30.1875L7.74816 25.0291C7.96481 24.0546 7.58152 22.6936 6.88159 21.9879L2.74872 17.8208C0.315651 15.3677 1.0989 12.8809 4.49852 12.3096L9.8146 11.4191C10.6978 11.2678 11.7644 10.4781 12.1643 9.65479L15.0974 3.7403C16.6972 0.531015 19.2969 0.531015 20.88 3.7403Z" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>}
              title={"Exclusive Perks"}
              subTitle={"Enjoy rewards and recognition for your efforts."}
            />
            <AmbassadorCard
              icon={<svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.5216C1 5.60047 1 5.13991 1.07149 4.7562C1.3884 3.05519 2.70942 1.72455 4.39815 1.40533C4.77909 1.33333 5.23632 1.33333 6.1508 1.33333H25.8492C26.7637 1.33333 27.2209 1.33333 27.6018 1.40533C29.2906 1.72455 30.6116 3.05519 30.9285 4.7562C31 5.13991 31 5.60047 31 6.5216C31 7.42532 31 7.87718 30.9475 8.29618C30.7181 10.1257 29.7519 11.7797 28.2756 12.8701C27.9375 13.1199 27.5453 13.3393 26.761 13.7782L22.4752 16.1765C19.3101 17.9477 17.7275 18.8333 16 18.8333C14.2725 18.8333 12.6899 17.9477 9.52476 16.1765L5.23896 13.7782C4.45467 13.3393 4.06253 13.1199 3.72441 12.8701C2.24808 11.7797 1.2819 10.1257 1.05253 8.29618C1 7.87718 1 7.42532 1 6.5216Z" stroke="#0066F3" stroke-width="2" stroke-linecap="round"/>
                <path d="M9.33398 6.33333V7.99999M16.0007 6.33333V11.3333M22.6673 6.33333V7.99999" stroke="#0066F3" stroke-width="2" stroke-linecap="round"/>
                <path d="M17.2957 20.7498L18.6156 23.4113C18.7956 23.7818 19.2755 24.1372 19.6805 24.2052L22.0727 24.606C23.6025 24.8631 23.9625 25.9821 22.8601 27.086L21.0003 28.9612C20.6854 29.2788 20.5129 29.8912 20.6104 30.3298L21.1428 32.651C21.5628 34.4884 20.5954 35.1992 18.983 34.2389L16.7408 32.9006C16.3358 32.6586 15.6684 32.6586 15.256 32.9006L13.0137 34.2389C11.4089 35.1992 10.434 34.4808 10.8539 32.651L11.3864 30.3298C11.4839 29.8912 11.3114 29.2788 10.9964 28.9612L9.13663 27.086C8.04175 25.9821 8.39421 24.8631 9.92404 24.606L12.3163 24.2052C12.7137 24.1372 13.1937 23.7818 13.3737 23.4113L14.6935 20.7498C15.4134 19.3056 16.5833 19.3056 17.2957 20.7498Z" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                }
              title={"Skill Development"}
              subTitle={"Build leadership and organizational skills."}
            />
            <AmbassadorCard
              icon={<svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.6254 25C35.8742 25 36.8675 24.2141 37.7594 23.1152C39.5851 20.8657 36.5875 19.068 35.4442 18.1877C34.282 17.2927 32.9843 16.7857 31.6686 16.6667M30.002 13.3333C32.3031 13.3333 34.1686 11.4678 34.1686 9.16665C34.1686 6.86546 32.3031 4.99998 30.002 4.99998" stroke="#0066F3" stroke-width="2" stroke-linecap="round"/>
                <path d="M5.3779 25C4.12906 25 3.13575 24.2141 2.24388 23.1152C0.418116 20.8657 3.41575 19.068 4.55904 18.1877C5.72126 17.2927 7.01894 16.7857 8.33463 16.6667M9.16797 13.3333C6.86678 13.3333 5.0013 11.4678 5.0013 9.16665C5.0013 6.86546 6.86678 4.99998 9.16797 4.99998" stroke="#0066F3" stroke-width="2" stroke-linecap="round"/>
                <path d="M13.4743 20.1853C11.7713 21.2383 7.30628 23.3885 10.0258 26.079C11.3543 27.3934 12.8338 28.3333 14.694 28.3333H25.3086C27.1688 28.3333 28.6483 27.3934 29.9768 26.079C32.6963 23.3885 28.2313 21.2383 26.5283 20.1853C22.5349 17.716 17.4677 17.716 13.4743 20.1853Z" stroke="#0066F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.8346 7.49999C25.8346 10.7217 23.223 13.3333 20.0013 13.3333C16.7796 13.3333 14.168 10.7217 14.168 7.49999C14.168 4.27833 16.7796 1.66666 20.0013 1.66666C23.223 1.66666 25.8346 4.27833 25.8346 7.49999Z" stroke="#0066F3" stroke-width="2"/>
                </svg>
                }
              title={"Community Impact"}
              subTitle={"Help make your community safer and more informed."}
            />
          </div>
        </div>
      </section>

      <section className="pt-12 md:py-[72px] px-4 md:px-60">
        <div className="flex flex-col">
          <p className="font-semibold text-3xl md:text-5xl text-neutralDark md:mt-20 md:mb-36 mt-4 mb-16 text-center">How does it work?</p>

          <div className="flex flex-col-reverse md:flex-row md:space-x-24">
            <div className="rounded-2xl my-12 md:my-0">
              <img
                src={aradAmbassador3}
                alt="aradAmbassador3"
                className="object-contain max-h-[650px] rounded-2xl"
              />
            </div>

            <div>
                <p className="text-neutralGrey font-normal text-xl leading-7 mb-6">Here’s how it works;</p>
                <div className="flex flex-col space-y-8">
                  <TextComponent title={"Sign Up: Fill out the form to express your interest."}/>
                  <TextComponent title={"Onboard: Receive tools, resources, and support to get started."}/>
                  <TextComponent title={"Engage Your Community: Use ARAD to connect and collaborate with neighbors."}/>
                  <TextComponent title={"Earn Rewards: Get bonuses for successful referrals and active participation."}/>
                </div>
                <p className="text-neutralGrey font-normal text-xl leading-7 my-6 ">Ready to Lead Your Community?</p>
                <TextButton
                  title={"Apply Now"}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-12 pb-12 md:pt-[120px] md:pb-[150px] px-4 md:px-60 bg-[#FFF0F0]">
          <div>
            <p className="font-semibold md:text-5xl text-3xl text-neutralDark text-center">Frequently Asked Questions</p>
            <div className="md:mt-20 md:mx-16 mt-8 mx-0">
                <FAQComponent
                  title={"Who can become an ambassador?"}
                  subTitle={"Anyone passionate about making their community better can apply!"}
                />
                 <FAQComponent
                  title={"What tools will I get?"}
                  subTitle={"Anyone passionate about making their community better can apply!"}
                /> 
                <FAQComponent
                  title={"Are there incentives?"}
                  subTitle={"Anyone passionate about making their community better can apply!"}
                />
            </div>
          </div>
      </section>

      <GeneralFooter />
    </div>
  );
};

export default Ambassador;
