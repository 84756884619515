import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  arad_logo,
  hamburger_menu,
  hamburger_menu_2,
} from "../../constants/images";
import { ImCancelCircle } from "react-icons/im";
import { homeRoutes } from "../../constants/data";
import TextButton from "../Button/TextButton";

export default function GeneralHeader({ mode = "light" }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const navigate = useNavigate();

  const handleOpenSidebar = () => {
    setActiveMenu(!activeMenu);
  };

  const linkStyle = `text-sm leading-5 font-SF-Pro-Regular ${
    mode === "light" ? "text-neutral" : "text-midnightNavy"
  }`;

  return (
    <header className="w-full flex justify-between px-10 pt-6 relative">
      <div
        className="cursor-pointer flex items-center space-x-2 justify-center"
        onClick={() => navigate("/")}
      >
        <img
          src={arad_logo}
          alt="aradugbo_logo"
          className="object-contain h-9"
        />
      </div>

      <div className="absolute pt-6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <ul 
        className={`hidden md:flex items-center space-x-8 rounded-full py-2 px-4 ${mode === "light" && "bg-white/20 backdrop-blur-sm"}`}>
          {homeRoutes.map((route, index) => (
            <li key={index}>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${linkStyle} font-bold` : linkStyle
                }
                to={route.path}
              >
                {route.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <div className="hidden md:flex space-x-8">
          <button
            onClick={() => navigate("/login")}
            className={`text-base font-normal font-SF-Pro-Regular ${
              mode === "light" ? "text-[#F9F9FB]" : "text-midnightNavy"
            }`}
          >
            LOG IN
          </button>
          <TextButton
            title={"Get Arad – It’s free"}
            onClick={() => {
              console.log("Pressed");
            }}
          />
        </div>

        <div className="cursor-pointer md:hidden" onClick={handleOpenSidebar}>
          <img
            src={mode === "light" ? hamburger_menu : hamburger_menu_2}
            alt="aradugbo_logo"
            className="object-contain"
          />
        </div>
      </div>

      {activeMenu && (
        <>
          <div className="w-[100%] h-[100%] bg-[#000] overflow-hidden fixed top-[0%] right-0 z-[9999999]  text-white">
            <ul className="w-[100%] h-[100%] flex flex-col justify-center items-center text-[30px]">
              {homeRoutes.map((route, index) => (
                <li onClick={handleOpenSidebar} key={index}>
                  <NavLink to={route.path}>{route.name}</NavLink>
                </li>
              ))}
            </ul>
            <div
              className="z-[999] w-[50px] h-[50px] absolute top-[10%] right-[3%] cursor-pointer"
              onClick={handleOpenSidebar}
            >
              <ImCancelCircle size={24} color="#fff" />
            </div>
          </div>
        </>
      )}
    </header>
  );
}
