import moment from "moment";

export const getRemainingDate = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  let time_difference = date2.getTime() - date1.getTime();
  let days_difference = time_difference / (1000 * 60 * 60 * 24);
  if (days_difference > 0) {
    return `${Math.round(days_difference)} days ago`;
  } else if (Math.round(days_difference) === 1) {
    return `${Math.round(days_difference)} day ago`;
  } else if (days_difference === 0) {
    return "today";
  }
};

export default class JWTDecoder {
  static decode(token) {
    // eslint-disable-next-line
    if (typeof window === undefined) {
    } else {
      return JSON.parse(window.atob(token.split(".")[1]));
    }
  }
}

export const formatDate = (str, format = "YYYY-MM-DD") =>
  moment(str).format(format);

export const formatTime = (str, format = "HH:mm:ss") =>
  moment(str).format(format);

export const duration = (x) => {
  const y = moment();
  return y.diff(x, "days");
};

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
export const goBack = (history) => {
  history.goBack();
};

export const isTokenExpired = (token) => {
  if (token) {
    const tokenDetail = JWTDecoder.decode(token);
    const expirationTime = tokenDetail?.exp; // Replace with your token expiration property
    return expirationTime < Date.now() / 1000;
  }
};

export const isDeleted = (user) => {
  if (user.isDeleted === true) {
    return true;
  }
};

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  const deg2rad = (deg) => deg * (Math.PI / 180);
  const calcSin = (dDis) => Math.sin(dDis / 2);
  const calcCos = (dDis) => Math.cos(deg2rad(dDis));

  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    calcSin(dLat) * calcSin(dLat) +
    calcCos(lat1) * calcCos(lat2) * calcSin(dLon) * calcSin(dLon);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Math.round(d);
};

export const getCoords = () => {
  let coord = {};
  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = position.coords;
              coord = { ...coord, coords };
              console.log(coord);
            },
            (error) => {
              console.error("Error getting location:", error);
            },
            { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
          );
          console.log(coord);
          return coord;
        }
      })
      .catch((err) => err);
  } else {
    console.error("Geolocation is not supported in this browser.");
  }
  return coord;
};
