import React, { useRef, useEffect } from "react";
import TextButton from "../Button/TextButton";

export function AmbassadorSuccessModal({ closeModal }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClick, true);

    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
    // eslint-disable-next-line
  }, [modalRef]);

  return (
    <div className={`fixed z-50 inset-0 flex items-center justify-center`}>
      <div ref={modalRef} className="flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 md:p-14 mx-6 flex flex-col text-center">
          <div className="self-center flex justify-center items-center mb-4 bg-[#D3F8DF] rounded-full md:p-12 p-10">
              <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M79.5 38.5715V42.0215C79.4954 50.108 76.8769 57.9765 72.035 64.4533C67.1931 70.9301 60.3873 75.6683 52.6326 77.9611C44.8779 80.2539 36.5897 79.9786 29.0043 77.1762C21.4188 74.3737 14.9425 69.1943 10.5411 62.4105C6.13975 55.6266 4.04922 47.6017 4.58129 39.5326C5.11336 31.4636 8.23953 23.7827 13.4935 17.6355C18.7476 11.4883 25.8479 7.20415 33.7357 5.42201C41.6235 3.63988 49.876 4.45523 57.2625 7.74647M79.5 12L42 49.5375L30.75 38.2875" stroke="#099250" stroke-width="7.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <p className="text-3xl font-semibold mb-4">Thank you for applying</p>
          <p className="text-base font-normal	text-center text-neutralGrey">
            You’ve taken the first step to making a difference. <br/>
            Stay tuned for an email with the next steps and more information about the program.
          </p>
          <TextButton
            style={"md:mt-20 mt-14"}
            title={"Close"}
            onClick={() => closeModal()}
          />
        </div>
      </div>
    </div>
  );
}
