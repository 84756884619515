import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function InviteCommunityModal({ open, onClose, submit }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const close = () => {
    onClose();
  };

  const submitInvite = () => {
    let payload = { email: email };
    submit(payload);
    onClose();
  };
  return (
    <Modal className="w-full " isOpen={open} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[1rem] mb-[1rem] align-middle  ">
            <h4 className="text-center mx-auto text-[20px]">Invite</h4>
            <div className="mt-[1rem] text-left font-SF-Pro-Regular">
              <h4 className="text-[20px]">Invite community members</h4>
              <p className="mt-[.2rem] font-[400] mb-[rem] text-[#60646c] text-[14px]">
                Allow your neighbours and community get the latest happening
                going on and also share information on the community.
              </p>
              <input
                type="email"
                placeholder="Enter email address"
                required
                onChange={(e) => setEmail(e.target.value)}
                className=" text-[#888d98] outline-none rounded-[4px] border-[1px]  border-[#02023414]  w-full mt-[2rem] mb-[1rem] text-center   py-[.5rem] px-1"
              />
              <button
                disabled={loading ? true : false}
                onClick={submitInvite}
                className="bg-[#f04438] font-[500] text-white w-full mt-[2rem] text-center rounded-[4px]  py-[.5rem] px-1"
              >
                Next
              </button>
              <button
                disabled={loading ? true : false}
                onClick={close}
                className="bg-[#fdd8d8] font-[500] text-[#f04438] w-full mt-[.5rem] text-center rounded-[4px]  py-[.5rem] px-1"
              >
                I’ll do that later
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default InviteCommunityModal;
