import React, { useState, useEffect } from "react";
import {
  post_message_icon,
  share_forward_icon,
} from "../../../constants/images";
import SharePost from "../SharePost";
import { useDisclosure } from "@chakra-ui/react";
import { AiOutlineHeart } from "react-icons/ai";
import { selectEmoticons, emotionsIcons } from "../../../constants/data";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { likeEvent } from "../../../redux/posts/posts.action";
import { motion } from "framer-motion";
import { userPostCommentStatus } from "../../../redux/api";

function UserPostShareHome({
  user,
  shared,
  page,
  openReportModal,
  index,
  theme,
  selectedPost,
  selectedPost: { id, likedBy, likeCount, commentCount, title },
}) {
  const [like, setLike] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [liked, setLiked] = useState(false);
  const [emotions, setEmoticons] = useState("");
  const [likes, setLikes] = useState(0);
  const dispatch = useDispatch();
  const [emoticon, setEmoticon] = useState("");
  const [open, setOpen] = useState(false);
  const [, setErrorMessage] = useState(false);
  const [post, setPost] = useState(selectedPost);
  const postLike = (item) => {
    setLike(!like);
    let payload = { reactionType: item };

    dispatch(likeEvent(id, payload))
      .then((data) => {
        const likeStatus = getLiked(data.data.likedBy);
        // updateCounts(data.data.likeCount);
        if (likes >= 0) {
          if (likeStatus) {
            getEmoticons(data.data.likedBy);
            setLikes(data.data.likeCount);
          } else {
            if (likes >= 1) setLikes(data.data.likeCount);
            setEmoticons("");
          }
        }
      })
      .catch((err) => {});
  };

  const getLiked = (data) => {
    if (data && data.length === 0) return false;
    else if (data && data.find((item) => item.user === user.username))
      return true;
  };

  const getEmoticons = (data) => {
    // eslint-disable-next-line
    data?.map((each) => {
      if (each?.user === user?.username) {
        setEmoticons(each?.reaction);
      }
    });
  };

  const clickIcon = (item, e) => {
    e.stopPropagation();

    setEmoticon(item);
    postLike(item);
    setLike(!like);
  };

  const ownership = () => {
    if (likedBy && likedBy?.length === 0) setEmoticons("");
    else if (
      likedBy !== undefined &&
      likedBy?.find((item) => item.user === user?.username)
    ) {
      // eslint-disable-next-line
      likedBy?.map((each) => {
        if (each.user === user?.username) {
          setEmoticons(each.reaction);
          setLiked(true);
        }
      });
    } else setEmoticons("");
  };

  useEffect(() => {
    ownership();
    // eslint-disable-next-line
  }, [likedBy]);

  useEffect(() => {
    setLikes(likeCount);
    // eslint-disable-next-line
  }, [likedBy]);

  const handleDropDown = () => {
    setOpen(!open);
  };

  const reportPost = (id) => {
    setOpen(!open);
    openReportModal(id);
  };

  const updatePostStatus = async (type) => {
    handleDropDown();
    let status = "on";

    if (type === "comment") {
      if (post?.comment === "on" || post?.comment === undefined) status = "off";
    } else {
      if (post?.notify === "on" || post?.notify === undefined) status = "off";
    }

    let updatedStatus = {};

    switch (type) {
      case "comment":
        updatedStatus = { comment: status };
        break;
      case "notify":
        updatedStatus = { notify: status };
        break;
      default:
        break;
    }

    try {
      const { data } = await userPostCommentStatus(id, { type, status });
      if (data) {
        setPost({
          ...post,
          ...updatedStatus,
        });
      }
    } catch (error) {
      const errorMes =
        typeof error?.data?.message === "string"
          ? error?.data?.message
          : "Something went wrong. Check your network connection";

      setErrorMessage(errorMes);
    }
  };
  return (
    <div
      key={index}
      className="flex  justify-between items-center cursor-pointer"
    >
      <div
        className={`flex justify-between items-center -ml-6  w-full ${
          theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
        } `}
      >
        <div className="flex">
          <div className="flex justify-between py-[.5rem] items-center">
            <div
              className={`flex items-center hover:bg-[#E5484D] hover:text-white ${
                theme
                  ? "bg-black text-white"
                  : "bg-[#F7F7F7] px-4 py-1 rounded-[25px]  text-[#27272E]"
              } `}
            >
              <div
                className="flex w-full  group relative items-center cursor-pointer "
                // onClick={() => handle()}
              >
                <button
                  class={`invisible group-hover:visible
            absolute h-[50px] left-0 bottom-4 pb-2 
            ml-0 flex justify-around  items-center rounded-[15px] cursor-pointer w-[200px] ${
              theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
            }  `}
                >
                  {emotionsIcons?.map((item) => (
                    <motion.img
                      initial={{ x: -100 }}
                      animate={{ x: 0 }}
                      exit={{ x: -100 }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      src={item?.icon}
                      alt={item?.name}
                      className="object-contain w-[13%]"
                      onClick={(e) => clickIcon(item?.name, e)}
                    />
                  ))}
                </button>

                {like ? (
                  selectEmoticons(emoticon)
                ) : liked ? (
                  <span onClick={(e) => clickIcon("", e)}>
                    {selectEmoticons(emotions)}
                  </span>
                ) : (
                  <AiOutlineHeart
                    size={24}
                    // onClick={(e) => clickIcon("love", e)}
                  />
                )}
              </div>
              <div className="ml-2">{likes} </div>
            </div>
            <div
              className={`flex items-center ml-6 cursor-pointer px-[.7rem] py-[.2rem] rounded-[20px]  ${
                theme ? "bg-black text-white" : " text-[#27272E]"
              } `}
            >
              <img src={post_message_icon} alt="message" />
              <span className="ml-[6px]">{commentCount}</span>
            </div>
          </div>
          {/* share */}
          <div
            onClick={onOpen}
            className={`flex ml-[1rem] justify-center  items-center  rounded-[21px] w-[124px]  ${
              theme ? "bg-black text-white" : " text-[#27272E]"
            } `}
          >
            <img src={share_forward_icon} alt="forward" />
            <span className="ml-[7px]">Share({shared ? shared : 0})</span>
          </div>
        </div>
        {page === "single" && (
          <div className="relative -mr-10">
            <div onClick={handleDropDown} className="cursor-pointer">
              <BsThreeDots size={24} />
            </div>
            {open && (
              <ul
                className={`absolute -top-[140px] rounded-[5px] cursor-pointer w-[190px] right-[2rem] px-[.1rem] py-2 pb-[1rem] mx-auto shadow text-[.8rem] 
                ${theme ? "bg-black text-white" : "bg-[#fff] text-kdark3"}`}
              >
                <li
                  onClick={() => reportPost(id)}
                  className="mb-[.4rem] rounded-[5px]  hover:bg-[#F2F4F7] py-[.5rem]  px-[1rem]"
                >
                  Report Post
                </li>
                <li
                  onClick={() => updatePostStatus("comment")}
                  className="mb-[.4rem] hover:bg-[#F2F4F7] py-[.5rem]  px-[1rem]"
                >
                  Turn {post?.comment === "off" ? "on" : "off"} commenting
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      {
        <SharePost
          theme={theme}
          isOpen={isOpen}
          onClose={onClose}
          id={id}
          title={title}
        />
      }
    </div>
  );
}

export default UserPostShareHome;
