import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserPosts } from "../../redux/peoplePost/peoplepost.actions";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { setIsLoading } from "../../redux/features/features.action";
import {
  allCommunities,
  allCommunityFeeds,
  joinCommunity,
  searchCommunity,
  searchEvents,
} from "../../redux/api";
import {
  logOut,
  setModalStatus,
  setStatus,
} from "../../redux/auth/auth.actions";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import EmptyState from "./EmptyState";
import CommunityCard from "./Cards";
import TitleHeader from "./TitleHeader";
import CreateCommunity from "./CreateCommunity";
import JoinCommunityModal from "./JoinCommunityModal";
import { toast } from "react-toastify";
import CommunityPostCard from "./CommunityPostCard";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";

function Community() {
  const { onOpen, onClose } = useDisclosure();
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const { user } = useSelector((state) => state.auth);
  const [page] = useState(1);
  const [, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [setLoading] = useState(false);
  const [loadingComm, setLoadingComm] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [, setTotalPages] = useState(0);
  const [newDoc, setNewDoc] = useState(null);
  const [, setIndex] = useState(2);
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const [community, setCommunity] = useState(null);
  const [selectedCommunityId, setSelectedCommunityId] = useState("");
  const [events, setEvents] = useState([]);
  const [feeds, setFeeds] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const { id } = useParams();
  const [currentLocation, setCurrentLocation] = useState({
    longitude: null,
    latitude: null,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  dayjs.extend(RelativeTime);

  useEffect(() => {
    dispatch(getAllUserPosts());
    dispatch(setIsLoading(true));
    // eslint-disable-next-line
  }, [token]);

  const handleSearch = async (more = false) => {
    setLoading(false);
    try {
      !more && setLoading(true);
      const { data } = await searchEvents({ page, search });
      if (data) {
        const documents = data?.hits?.map((item) => {
          return {
            ...item.document,
            media: {
              images: item.document["media.images"],
              videos: item.document["media.videos"],
            },
          };
        });
        setEvents(page === 1 ? documents : [...events, ...documents]);
        setTotalPages(Math.ceil(data.found / data.request_params.per_page));
        setLoading(false);
        setLoadingMore(false);
      }
    } catch (error) {
      setLoading(false);
      // Sentry.Native.captureException(error);
    }
  };

  const visibleTodos = useCallback(() => {
    getCommunities(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    visibleTodos();
    // eslint-disable-next-line
  }, []);

  const getCommunities = useCallback(
    async () => {
      try {
        setLoadingComm(true);
        const { data } = await allCommunities(newDoc);
        if (data) {
          setNewDoc(data?.data?.lastDoc);
          setCommunity(data?.data?.communities);
          // setCommunity([...community, ...data?.data?.communities]);
          setLoadingComm(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoadingComm(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    [newDoc]
  );

  useEffect(() => {
    if (user.isDeleted) {
      dispatch(setStatus(true));
      dispatch(logOut());
    }
    // eslint-disable-next-line
  }, [user]);

  const handleModalCreateCommunity = () => {
    onOpen();
    dispatch(setModalStatus(71));
  };

  const CloseCommunityModal = () => {
    onClose();
    dispatch(setModalStatus(0));
  };

  const memberJoinCommunity = async (code, id) => {
    setSelectedCommunityId(id);
    onOpen();
    dispatch(setModalStatus(72));
    await submit(code);
  };

  const submit = async (id) => {
    try {
      setLoading(true);
      const { data } = await joinCommunity(id);
      if (data) {
        setLoading(false);
        toast.success(data?.message);
        onClose();
        getCommunities();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;

                setCurrentLocation({ longitude, latitude });

                setTrigger(!trigger);
              },
              (error) => {
                console.error("Error getting location:", error);
              },
              { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
            );
          }
        })
        .catch((err) => err);
    } else {
      console.error("Geolocation is not supported in this browser.");
    }

    // eslint-disable-next-line
  }, []);

  const getFeeds = useCallback(
    async () => {
      try {
        setisLoading(true);
        const { data } = await allCommunityFeeds(
          currentLocation?.latitude,
          currentLocation?.longitude
        );

        if (data) {
          setFeeds(data?.data?.communities);
          setisLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setisLoading(false);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    getFeeds();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!token) {
    }
    // eslint-disable-next-line
  }, []);

  const handleSearchCommunity = useCallback(
    async (e) => {
      setLoading(true);
      try {
        const { data } = await searchCommunity(e, page);
        if (data) {
          setNewDoc(data?.data?.lastDoc);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [page]
  );

  const viewTab = (id) => {
    navigate(`/dashboard/community/${id}`);
  };
  return (
    <DashboardLayout>
      <TitleHeader click={handleModalCreateCommunity} />
      <div className="bg-white h-[100vh]">
        <div
          className={`w-full h-auto  ${
            darktheme
              ? "bg-kdark3 text-[#F9FAFB]"
              : "bg-[#fff] text-[text-[#27272E]"
          }`}
        >
          <div className="max-w-[600px] mx-auto  pt-[rem]">
            <div className="flex w-full mb-[2rem]">
              <p
                className={`font-SF-Pro-Regular cursor-pointer p-1 w-[50%] text-center font-[590] text-[14px] text-[#27272e] ${
                  id === "feeds" && "bord-gr "
                }   pb-[.8rem]  `}
                onClick={() => viewTab("feeds")}
              >
                My Feeds
              </p>
              <p
                onClick={() => viewTab("communities")}
                className={`font-SF-Pro-Regular w-[50%] cursor-pointer text-center font-[590] text-[14px] text-[#27272e] ${
                  id === "communities" && "bord-gr "
                }  `}
              >
                My Communities
              </p>
            </div>

            {id === "feeds" && (
              <>
                {isLoading ? (
                  <div className="flex h-[50vh] justify-center items-center">
                    <Loader big={true} />
                  </div>
                ) : feeds?.length === 0 ? (
                  feeds?.length === 0 && (
                    <div className="flex h-[50vh] items-center">
                      <EmptyState click={handleModalCreateCommunity} />
                    </div>
                  )
                ) : (
                  <div>
                    <div
                      className={`flex items-center w-full h-[50px] mb-[1rem] px-5 ${
                        darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
                      } rounded-[43px]`}
                    >
                      <input
                        onKeyDown={(e) => handleSearchCommunity(e.target.value)}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search feeds"
                        className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
                      />
                      <CiSearch size={24} onClick={handleSearch} />
                    </div>
                    {feeds &&
                      feeds?.map((item) => (
                        <CommunityPostCard
                          data={item}
                          join={memberJoinCommunity}
                          coords={currentLocation}
                        />
                      ))}
                  </div>
                )}
              </>
            )}
            {id === "communities" && (
              <>
                {loadingComm ? (
                  <div className="flex h-[50vh] items-center justify-center">
                    <Loader big={true} />
                  </div>
                ) : // community?.filter((item) => item?.isMember).length === 0 ? (
                community?.filter((item) => item?.isMember).length === 0 ? (
                  <div className="flex h-[50vh] items-center">
                    <EmptyState click={handleModalCreateCommunity} />
                  </div>
                ) : (
                  <div>
                    <div
                      className={`flex items-center w-full h-[50px] mb-[1rem] px-5 ${
                        darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
                      } rounded-[43px]`}
                    >
                      <input
                        onKeyDown={handleSearchCommunity}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search Community"
                        className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
                      />
                      <CiSearch size={24} onClick={handleSearch} />
                    </div>
                    {/* // ?.filter((item) => item?.isMember) */}
                    {community &&
                      community?.map((item) => (
                        <CommunityCard data={item} join={submit} />
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {uploadModalStatus === 71 && (
        <CreateCommunity
          open={uploadModalStatus === 71}
          type={"My"}
          onClose={CloseCommunityModal}
          refresh={getCommunities}
        />
      )}
      {uploadModalStatus === 72 && (
        <JoinCommunityModal
          id={selectedCommunityId}
          open={uploadModalStatus === 72}
          type={"My"}
          onClose={CloseCommunityModal}
        />
      )}
    </DashboardLayout>
  );
}

export default Community;
