import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function JoinCommunityModal({ open, onClose, id }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const close = () => {
    onClose();
    navigate(`/dashboard/community/view/${id}`);
  };
  return (
    <Modal className="w-full " isOpen={open} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[1rem] mb-[1rem] align-middle  ">
            <h4 className="text-center mx-auto text-[20px]">Add photo</h4>
            <div className="mt-[1rem] text-center">
              <div className="w-[150px] h-[150px] bg-[#f2f2f5] mb-[1rem] rounded-[50%] mx-auto"></div>
              <h4 className="text-[20px]">
                You’re in! Now add your profile photo
              </h4>
              <p className="mt-[.2rem] font-[400] mb-[rem] text-[#60646c] text-[14px]">
                Help your neighbours recognize you.
                <br /> Over 1m arad users use photos.
              </p>
              <button
                disabled={loading ? true : false}
                className="bg-[#f04438] text-white w-full mt-[2rem] text-center rounded-[4px]  py-[.5rem] px-1"
              >
                {loading ? "Uploading" : "Upload"}
              </button>
              <button
                disabled={loading ? true : false}
                onClick={close}
                className="bg-[#fdd8d8] text-[#f04438] w-full mt-[.5rem] text-center rounded-[4px]  py-[.5rem] px-1"
              >
                I’ll do that later
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default JoinCommunityModal;
