import React from "react";
import { useNavigate } from "react-router-dom";

function CommunityCard({ data, join }) {
  const navigate = useNavigate();

  const ViewCommunity = (id) => {
    navigate(`/dashboard/community/view/${id}`);
  };
  return (
    <div className="flex mb-[.2rem]  justify-between">
      <div className="flex mb-[1rem]  gap-[10px]">
        <div className="rounded-[12px] bg-black w-[60px]  h-[60px] ">
          {data?.communityCoverPhoto && (
            <img
              src={data?.communityCoverPhoto}
              alt="post pics"
              className="w-full h-full object-cover rounded-[12px]"
            />
          )}
        </div>
        <div className="">
          <h4 className="text-[14px] text-[#1d2939]">{data?.communityName}</h4>
          <p className="text-[14px] text-[#667085]">
            {data?.memberCount} members
          </p>
        </div>
      </div>
      <div>
        {data?.isMember ? (
          <button
            onClick={() => ViewCommunity(data?.id)}
            className="bg-transparent border-[1px] hover:cursor-pointer border-[#641723]  text-[14px] text-[#641723] px-[1rem] py-[.5rem] rounded-[10px]"
          >
            View
          </button>
        ) : (
          <button
            onClick={() => join(data?.communityCode, data?.id)}
            className="bg-[#f9c6c6] text-[14px] cursor-pointer text-[#641723] px-[1rem] py-[.5rem] rounded-[10px]"
          >
            Join
          </button>
        )}
      </div>
    </div>
  );
}

export default CommunityCard;
