import React from 'react'

export default function AmbassadorCard({ title, subTitle, icon }) {
  return (
    <div className="flex flex-col bg-white px-[30px] pt-[30px] pb-[84px] space-y-5 rounded-xl">
        <div className="flex w-[70px] h-[70px] bg-custom-blue p-5 items-center justify-center">
          {icon}
        </div>
        <p className="text-[#000000] font-semibold text-2xl">{title}</p>
        <p className="text-[#000000] font-normal text-xl">{subTitle}</p>
    </div>
  )
}
