import React, { useEffect, useState } from "react";
import { FaRegComment } from "react-icons/fa6";
import { RiQuestionnaireLine } from "react-icons/ri";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { AiOutlineUserAdd } from "react-icons/ai";
import Flvplayer from "./VideoPlayer/FlvPlayer";
import { IoClose } from "react-icons/io5";
import Commentcard from "./CommentCard";
import { formatDate } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import SocketManager from "../../socket/socketManager";
import { postMsg } from "../../redux/live/live.action";
import { useNavigate } from "react-router-dom";

function LivestreamCard({ stream }) {
  const [showComment, setShowComment] = useState(false);
  const [chatMsg, setChat] = useState("");
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    e.preventDefault();
    setChat(e.target.value);
  };

  const sendMessage = () => {
    if (chatMsg === "") {
    } else {
      const payload = {
        roomId: stream?.roomId,
        user: user?.username,
        question: chatMsg,
        message: chatMsg,
      };
      const response = dispatch(postMsg(payload));
      SocketManager.instance?.emitSendMessage(payload);
      // SocketManager.instance.emitAskQuestion(payload);
      SocketManager.instance.listenSendMessage(payload);
      setChat("");
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, [token]);
  return (
    <div
      className="mx-auto w-[536px]  border-b-[1px] border-[#02023414] mb-[2rem] pb-[1rem] cursor-pointer"
      // onClick={() => view(stream?.roomId)}
    >
      <div className="w-full relative h-[600px]  rounded-[10px]">
        {stream && stream?.liveStatus === 1 && stream?.roomId ? (
          <Flvplayer
            url={`https://livestream.myaradugbo.com/live/${stream?.roomId}.flv`}
            type="flv"
            className="rounded-[10px]"
          />
        ) : (
          <video controls className="w-full rounded-[10px] h-full object-cover">
            <source src={stream?.replayLink} type="video/mp4" />
            {/* <source src="movie.ogg" type="video/ogg" /> */}
            Your browser does not support the video tag.
          </video>
        )}
        <div className="ml-4 absolute text-white bottom-28 left-0">
          <div className="flex mb-1 items-center">
            <span className="bg-[#fc5c65] font-thin text-[12px] rounded-[15px] px-[.8rem] text-white">
              Live
            </span>
            <p className=" font-thin ml-[.5rem] text-[12px]">
              {stream?.viewers?.length} people watching
            </p>
          </div>
          <h4 className="text-white mb-[.5rem] font-bold ">{stream?.title}</h4>
          <div className="flex mb-1 items-center">
            <img
              src={stream?.host?.imageUrl}
              alt="User"
              className="w-[30px] h-[30px] rounded-full object-cover"
            />
            <p className="text-[.7rem] ml-[.5rem] capitalize  text-white">
              {stream?.host?.displayName || stream?.host?.username}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 mt-4 absolute bottom-20 right-4 ">
          <img
            src={stream?.host?.imageUrl}
            alt="User"
            className="w-[48px] h-[48px] rounded-full object-cover"
          />
          <div className="flex  h-[68px] w-[40px] text-white justify-between bg-[#838181c0] items-center p-[8px] flex-col backdrop-blur rounded-[25px]">
            <div onClick={() => setShowComment(true)}>
              <FaRegComment className="text-white w-[24px] h-[24px]" />
            </div>
            <p>{stream?.messages?.length}</p>
            {showComment && (
              <div className="shadow-md text-black pb-4 bg-white w-[300px] h-[ px-2 rounded-md absolute bottom-[5rem] -right-[16rem]">
                <div className="my-2">
                  <IoClose
                    className="text-black rounded-[50%] p-1 bg-[#F2F4F7] w-[24px] h-[24px] float-right"
                    onClick={() => setShowComment(false)}
                  />
                  <h4 className="text-center">Comments</h4>
                </div>
                <div className="h-[230px] overflow-y-scroll">
                  {stream?.messages?.length > 0 ? (
                    stream?.messages.map((item) => (
                      <Commentcard
                        image={item?.user?.imageUrl}
                        name={item?.user?.displayName || item?.user}
                        date={formatDate(item?.createdAt)}
                        text={item?.message}
                      />
                    ))
                  ) : (
                    <p className="text-center my-[6">No comments yet</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center my-4 w-fill ">
        <div className="flex w-[70%]  items-center px-[1rem] h-[40px] text-[12px] outline-none bg-transparent border-[1px] py-[.3rem] border-[#60646c] rounded-[31px]">
          <input
            className="w-full border-none outline-none bg-transparent"
            placeholder="Send a message"
            name="message"
            required
            onChange={(e) => handleChange(e)}
          />
          <button
            className="border-0 bg-transparent text-[#E4405F]"
            onClick={(e) => sendMessage(e)}
          >
            Post
          </button>
        </div>
        <div className="flex justify-between py-[.2rem] items-center w-[28%]">
          <RiQuestionnaireLine className="w-[24px] rounded-md ml-[1rem] h-[24px] text-[#60646c]" />
          <AiOutlineUserAdd className="w-[24px] h-[24px] text-[#60646c]" />
          <HiOutlineDotsHorizontal className="w-[24px] h-[24px] text-[#60646c]" />
        </div>
      </div>
    </div>
  );
}

export default LivestreamCard;
