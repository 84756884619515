import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createACommunity } from "../../redux/api";
import Loader from "../Loader";

function CreateCommunity({ open, onClose, refresh }) {
  const [formData, setFormData] = useState({
    communityName: "",
    communityDesc: "",
    communityType: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await createACommunity(formData);
      if (data) {
        toast.success(data?.message);
        setLoading(false);
        refresh();
        onClose();
        navigate("/dashboard/community/communities");
      }
    } catch (error) {
      onClose();
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Modal className="w-full " isOpen={open} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div className="mt-[1rem] mb-[1rem] align-middle  ">
            <h4 className="text-center mx-auto text-[20px]">Create Commu...</h4>
            <form className="mt-[1rem]" onSubmit={submit}>
              <h4 className="text-[20px]">Create a new community</h4>
              <p className="mt-[.5rem] font-[400] mb-[1rem] text-[#60646c] text-[14px]">
                Allow your neighbours and community get the latest happening
                going on and also share information on the community.
              </p>
              <input
                type="text"
                name="communityName"
                onChange={(e) => handleChange(e)}
                placeholder="Enter community name"
                className="w-full border-[1px]  font-[400] rounded-[4px]  mb-[.4rem] border-[#d0d5dd] py-[.4rem] px-1"
              />
              <textarea
                onChange={(e) => handleChange(e)}
                placeholder="Enter community description"
                name="communityDesc"
                className="w-full border-[1px]  font-[400] h-[125px] mb-[.4rem] rounded-[4px] border-[#d0d5dd] py-[.5rem] px-1"
              />
              <select
                onChange={(e) => handleChange(e)}
                name="communityType"
                className="w-full border-[1px] bg-[#f2f2f5] rounded-[4px] border-[#d0d5dd] py-[.5rem] px-1"
              >
                <option>Anyone can join</option>
                <option value="public">Yes</option>
                <option value="private">No</option>
              </select>
              {loading ? (
                <Loader />
              ) : (
                <button
                  disabled={loading ? true : false}
                  className="bg-[#f04438] text-white w-full mt-[2rem] text-center rounded-[4px]  py-[.5rem] px-1"
                >
                  {loading ? "Creating" : "Create Community"}
                </button>
              )}
            </form>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateCommunity;
