import React from "react";
import AlarmIcon from "../../assets/images/alarm.png";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import LockIcon from "../../assets/images/lock-circle.svg";
import { getDistanceFromLatLonInKm } from "../../utils/utils";
import { useSelector } from "react-redux";
import CommunityPostShare from "../SingleUserPost/UserPostShare/CommunityPostLike.jsx";

function CommunityPostCard({
  data,
  coords,

  index,
}) {
  const darktheme = useSelector((state) => state.features.settings.darktheme);

  return (
    <div className="border-b-[8px] mb-[1.5rem]  border-b-[#49496106]">
      <div className="flex justify-between">
        <div className="flex">
          <div className="w-[40px] h-[40px] bg-black rounded-[50%]">
            {data?.userImage && (
              <img
                src={data?.userImage}
                alt="icon"
                className="w-full object-cover rounded-[50%] h-full"
              />
            )}
          </div>
          <div className="ml-[.5rem] font-SFProRegular">
            <h4 className="capitalize font-SFProRegular font-[510] text-[#1c2024]">
              {data?.displayName || data?.username}
            </h4>
            <div className="flex text-[12px] items-center text-[#667085]">
              <img
                src={LockIcon}
                alt="icon"
                className="w-[18px] mr-[.3rem] h-[18px]"
              />

              <p className="text-[12px] capitalize text-[#667085] font-[400] ">
                {data?.community?.communityName}
              </p>
              <p className="w-[.20rem]  h-[.20rem] mx-[.3rem] rounded-[50%] bg-gray-400"></p>
              <p className="text-[12px] text-[#667085] ">4d ago</p>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="mr-2">
            <p className="text-[#FC5C65] bg-[#FC5C651A] rounded-[15px] px-[.5rem] py-[.2rem] text-[12px] font-semibold ml-[6px]">
              {getDistanceFromLatLonInKm(
                coords?.latitude,
                coords?.longitude,
                data?.coords?.latitude,
                data?.coords?.longitude
              )}
              km
            </p>
          </div>
          <HiOutlineDotsHorizontal className="mr-1" size={24} />
        </div>
      </div>
      <div className="">
        <p className="w-[70%] my-[.7rem] ">{data?.title}</p>
        <div className="flex justify-center w-[200px] bg-[#ddf3e4]  text-[12px] px-2 rounded-[25px] items-center ">
          <img
            src={AlarmIcon}
            alt="icon"
            className="object-contain w-[24px] mr-[.3rem] h-[24px] p-1"
          />{" "}
          <p> {data?.category}</p>
        </div>

        <div className="bg-black mt-2 w-full h-[221px] rounded-[4px]">
          <img
            src={data?.media?.images[0]}
            alt="visual"
            className="w-full h-full object-cover rounded-[4px]"
          />
        </div>

        <CommunityPostShare
          theme={darktheme}
          index={index}
          selectedPost={data}
        />
      </div>
    </div>
  );
}

export default CommunityPostCard;
