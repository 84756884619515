import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/Eina01/Eina01-Regular.ttf";
import "./assets/fonts/Eina01/Eina01-SemiBold.ttf";
import "./assets/fonts/Eina01/Eina01-Bold.ttf";
import "./assets/fonts/Eina01/Eina01-Light.ttf";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ErrorBoundary from "./ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="271509946343-av44nm2mnh50pm8g1fek7h2uq8cmlt7q.apps.googleusercontent.com">
    <ErrorBoundary>
      <React.Fragment>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ChakraProvider>
              <App />
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </React.Fragment>
    </ErrorBoundary>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
