import About from "../pages/About/About";
import Auth from "../pages/Auth/Auth";
import Blog from "../pages/Blog/Blog";
import Ambassador from "../pages/Ambassador/Ambassador";
import Home from "../pages/Home/Home";
import Privacy from "../components/Privacy/Privacy";
import SinglePost from "../pages/SinglePost/SinglePost";
import {
  people1,
  people2,
  people3,
  people4,
  people6,
  flatPhone,
  people7,
  people8,
  people10,
  people11,
  people9,
  flatPhone2,
  merchantMap,
  people12,
  people13,
  people14,
  people15,
  incident_post,
  angry,
  cry,
  love,
  like,
  laugh,
  light,
  groupIcon,
  eyeIcon,
  arrowIcon,
} from "./images";
import { LuHeartHandshake } from "react-icons/lu";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import { FiUser } from "react-icons/fi";
import { MdOutlineExplore } from "react-icons/md";
import { RiHome6Line, RiSearchLine } from "react-icons/ri";
import Policy from "../components/Privacy/policy";
import UserDeletion from "../components/Privacy/userDeletion";
import Term from "../components/Privacy/term";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { PrivateRoute } from "../pages/Dashboard/ProtectedRoute";
import HomeNG from "../pages/Home/HomeNG";
import { FaChromecast } from "react-icons/fa6";
import { FiAlertTriangle } from "react-icons/fi";

const emotionsIcons = [
  {
    name: "angry",
    icon: angry,
  },
  {
    name: "cry",
    icon: cry,
  },
  {
    name: "laugh",
    icon: laugh,
  },
  {
    name: "like",
    icon: like,
  },
  {
    name: "light",
    icon: light,
  },
  {
    name: "love",
    icon: love,
  },
];

export const selectEmoticons = (emoticons) => {
  if (emoticons === "") {
    return <AiOutlineHeart name="hearto" size={20} className="b" />;
  }
  if (emoticons === "laugh") {
    return <img src={laugh} className="object-contain w-7" alt="icon" />;
  }
  if (emoticons === "cry") {
    return <img src={cry} className="object-contain w-7" alt="icon" />;
  }
  if (emoticons === "like") {
    return <img src={like} className="object-contain w-7" alt="icon" />;
  }
  if (emoticons === "love") {
    return <AiFillHeart name="hearto" size={24} className="text-red-600" />;
  }
  if (emoticons === "light") {
    return <img src={light} className="object-contain w-7" alt="icon" />;
  }
  if (emoticons === "angry") {
    return <img src={angry} className="object-contain w-7" alt="icon" />;
  }
};

const ltr = [
  {
    img: people1,
    alt: "4men embracing",
  },
  {
    img: people2,
    alt: "people joining hands",
  },
  {
    img: people3,
    alt: "helping the needy",
  },
  {
    img: people4,
    alt: "two individual holding hands",
  },
  {
    img: people12,
    alt: "blacks people embracing",
  },
  {
    img: people13,
    alt: "little uganda girl",
  },
  {
    img: people15,
    alt: "ladies on beach",
  },
];

const rtl = [
  {
    img: people6,
    alt: "rock",
  },
  {
    img: people14,
    alt: "guy on the parlour",
  },
  {
    img: people7,
    alt: "people on the street",
  },
  {
    img: people8,
    alt: "zebra crossing",
  },
  {
    img: people9,
    alt: "boy with a ball",
  },
  {
    img: people10,
    alt: "blacks people embracing",
  },
  {
    img: people11,
    alt: "sister in a gray background",
  },
];

const productFeatures = [
  {
    title: "SHARE REAL-TIME UPDATES",
    icon: groupIcon,
    image: flatPhone,
    description: "With Arad community app, you can share real information on",
    features: [
      "Crime and suspicious activities",
      "Environmental activities to help make your community a more cleaner one",
      "Initiatives that will aid the development of your community",
    ],
  },
  {
    title: "GET REAL-TIME UPDATES",
    icon: eyeIcon,
    image: merchantMap,
    features: [
      "Updates on Incidents happening around you in real time",
      "Receive the fastest traffic updates",
      "Get live video feed or updates of incidents from the comfort of your home.",
    ],
  },
  {
    title: "INTERACT WITH YOUR COMMUNITY",
    icon: arrowIcon,
    image: flatPhone2,
    description:
      "Connect with your community effortlessly through the Arad app.",
    features: [
      "Discover Local Businesses Easily: Find the services you need within your community effortlessly.",
      "Stay Informed with Our user-friendly dashboard providing real-time updates.",
    ],
  },
];

const aboutFeatures = [
  {
    title: "SHARE REAL-TIME UPDATES",
    icon: groupIcon,
    shortDescription:
      "Get safety alerts when they matter to you — in real time.",
    description: "With Arad community app, you can share real information on",
    features: [
      "Crime and suspicious activities",
      "Environmental activities to help make your community a more cleaner one",
      "Initiatives that will aid the development of your community",
    ],
  },
  {
    title: "GET REAL-TIME UPDATES",
    shortDescription:
      "Know what’s happening so you and your loved ones can stay safe.",
    icon: eyeIcon,
    features: [
      "Updates on Incidents happening around you in real time",
      "Receive the fastest traffic updates",
      "Get live video feed or updates of incidents from the comfort of your home.",
    ],
  },
  {
    title: "INTERACT WITH YOUR COMMUNITY",
    icon: arrowIcon,
    shortDescription:
      "When you can, share and contribute to help resolve a situation.",
    description:
      "You used to have to call a police tip line to help. Now you can use Aradugbo to broadcast live video, sharing relevant updates with others. You can also leave comments, which have helped locate missing people and share information about evacuation centers during natural disasters.",
    features: [
      "Updates on Incidents happening around you in real time",
      "Receive the fastest traffic updates",
      "Get live video feed or updates of incidents from the comfort of your home.",
    ],
  },
];

const publicLink = [
  {
    name: "Home",
    path: "/dashboard/home",
    title: "Home",
    icon: <RiHome6Line className="w-[2rem] hover:text-[#FC5059] h-[2rem]" />,
    privateRoute: false,
  },
  {
    name: "search",
    path: "/dashboard/search",
    title: "Search",
    icon: <RiSearchLine className="w-[2rem] h-[2rem]" />,
    privateRoute: false,
  },
  {
    name: "Livestream",
    path: "/dashboard/livestream",
    title: "Livestream",
    icon: <FaChromecast className="w-[2rem] hover:text-[#FC5059]  h-[2rem]" />,
    privateRoute: true,
  },
  {
    name: "Explore",
    path: "/dashboard/explore",
    title: "Explore",
    icon: (
      <MdOutlineExplore className="w-[2rem] hover:text-[#FC5059]  h-[2rem]" />
    ),
    privateRoute: false,
  },
  {
    name: "alert",
    path: "/dashboard/alert",
    title: "Alert",
    icon: (
      <FiAlertTriangle className="w-[2rem] hover:text-[#FC5059]  h-[2rem]" />
    ),
    permission: ["partner"],
    privateRoute: true,
  },
  {
    name: "community",
    path: "/dashboard/community/feeds",
    title: "Community",
    icon: (
      <LuHeartHandshake className="w-[2rem] hover:text-[#FC5059]  h-[2rem]" />
    ),
    // permission: ["partner"],
    privateRoute: true,
  },
  {
    name: "Profile",
    path: "/dashboard/profile",
    title: "Profile",
    icon: (
      <FiUser className="w-[1.5rem] hover:text-[#FC5059] text-[#60646C] h-[1.5rem]" />
    ),
    // iconActive: <CubeIconSolid className="w-full" />,
    privateRoute: true,
  },
];

const myroutes = [
  {
    path: "/",
    route: <Home />,
  },
  {
    path: "/ng",
    route: <HomeNG />,
  },
  {
    path: "/ambassador",
    route: <Ambassador />,
  },
  {
    path: "/about",
    route: <About />,
  },
  {
    path: "blog",
    route: <Blog />,
  },
  {
    path: "/singlepost/:id",
    route: <SinglePost />,
  },
  {
    path: "/dashboard/*",
    route: <PrivateRoute />,
  },
  {
    path: "/login",
    route: <Auth />,
  },
  {
    path: "/signup",
    route: <Auth />,
  },
  {
    path: "/resetpassword",
    route: <Auth />,
  },
  {
    path: "/coming-soon",
    route: <ComingSoon />,
  },
  {
    path: "/legal",
    route: <Privacy />,
  },
  {
    path: "/legal/privacy-policy",
    route: <Policy />,
  },
  {
    path: "/legal/user-data-deletion",
    route: <UserDeletion />,
  },
  {
    path: "/legal/terms-of-use",
    route: <Term />,
  },
];

export const onlinePeople = [
  {
    id: 1,
    name: "milliarta",
    photo: people1,
  },
  {
    id: 2,
    name: "mimi",
    photo: people4,
  },
  {
    id: 3,
    name: "mike",
    photo: people3,
  },
  {
    id: 4,
    name: "mike",
    photo: people7,
  },
  {
    id: 5,
    name: "mike",
    photo: people3,
  },
  {
    id: 6,
    name: "mike",
    photo: people10,
  },
  {
    id: 7,
    name: "mike",
    photo: people11,
  },
  {
    id: 8,
    name: "mike",
    photo: people13,
  },
  {
    id: 9,
    name: "mike",
    photo: people2,
  },
  {
    id: 10,
    name: "mike",
    photo: people14,
  },
  {
    id: 11,
    name: "mike",
    photo: people3,
  },
  {
    id: 12,
    name: "mike",
    photo: people3,
  },
  {
    id: 13,
    name: "mike",
    photo: people3,
  },
];

const peoplepost = [
  {
    id: 1,
    name: "Annaclaramm",
    title: "Local Hiker Falls in to a lagoon",
    image: incident_post,
    messageCount: 504,
  },
  {
    id: 2,
    name: "Annaclaramm",
    title: "Falls in to a lagoon",
    image: incident_post,
    messageCount: 504,
  },
  {
    id: 3,
    name: "sik",
    title: " a lagoon",
    image: incident_post,
    messageCount: 504,
  },
  {
    id: 4,
    name: "love india",
    title: "Local Hiker Falls in to a lagoon",
    image: incident_post,
    messageCount: 504,
  },
  {
    id: 5,
    name: "Annaclaramm",
    title: "Local Hiker Falls in to a lagoon",
    image: incident_post,
    messageCount: 504,
  },
];

const homeRoutes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Blog",
    path: "https://medium.com/@arad_community",
  },
];

const footerRoutes = [
  {
    name: "Careers",
    path: "/",
  },
  {
    name: "Blog",
    path: "https://medium.com/@arad_community",
  },
  {
    name: "Discord",
    path: "https://discord.com",
  },
  {
    name: "X.com",
    path: "https://twitter.com/MyAradugbo",
  },
  {
    name: "Instagram",
    path: "https://www.instagram.com/MyAradugbo/",
  },
  {
    name: "Facebook",
    path: "https://web.facebook.com/myaradugbo",
  },
  {
    name: "Legal",
    path: "/legal",
  },
];

export {
  ltr,
  rtl,
  homeRoutes,
  emotionsIcons,
  publicLink,
  myroutes,
  peoplepost,
  productFeatures,
  footerRoutes,
  aboutFeatures,
};
