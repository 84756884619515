import React, { useState, useRef, useEffect } from "react";
import { closeCircleIcon } from "../../constants/images";
import { joinAmbassadorProgram } from "../../redux/api";
import TextButton from "../Button/TextButton";
import { AmbassadorSuccessModal } from "./AmbassadorSuccessModal";

export function AmbassadorProgramModal({ closeModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs(values => ({...values, [name]: value}));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateInputs();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsOpen(true);

    const payload = {
      email: inputs.email, 
      firstName: inputs.firstName, 
      lastName: inputs.lastName, 
      phoneNumber: inputs.phoneNumber, 
      location: inputs.location, 
      reasonForJoining: inputs?.reason ?? null
    }
    await joinAmbassadorProgram(payload);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!inputs.firstName || inputs.firstName.trim() === "") {
      newErrors.firstName = "First Name is required.";
    }
    if (!inputs.lastName || inputs.lastName.trim() === "") {
      newErrors.lastName = "Last Name is required.";
    }
    if (!inputs.email || !/\S+@\S+\.\S+/.test(inputs.email)) {
      newErrors.email = "A valid Email is required.";
    }
    if (!inputs.phoneNumber || inputs.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Phone number is required.";
    }
    if (!inputs.location || inputs.location.trim() === "") {
      newErrors.location = "Location is required.";
    }

    return newErrors;
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClick, true);

    return () => {
      document.removeEventListener("mousedown", handleClick, true);
    };
  }, [modalRef]);

  return (
    <div className={`fixed z-50 inset-0 flex items-center justify-center`}>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    {isOpen && (
        <AmbassadorSuccessModal
          closeModal={() => {
            closeModal();
            setIsOpen(false);
          }}
        />
      )}
      {!isOpen && (
        <div ref={modalRef}  className="relative bg-white rounded-lg pt-5 pb-9 flex flex-col overflow-y-scroll md:max-h-auto max-h-screen w-full md:w-auto">
          <div className="bg-white rounded-lg pt-10 md:pt-5 pb-9 flex flex-col">
            <div
              className="self-end mr-5 cursor-pointer"
              onClick={() => {
                closeModal();
                setIsOpen(false);
                document.body.classList.remove("overflow-hidden");
              }}
            >
              <img
                src={closeCircleIcon}
                alt="closeCircleIcon"
                className="object-contain w-6 h-6"
              />
            </div>
            
            <div className="md:px-12 px-6">
              <div className="flex space-y-2 flex-col md:mt-3">
                <p className="md:text-3xl text-xl text-[#183649] font-semibold font-Eina01-Bold leading-9">
                  Become an ambassador
                </p>
                <p className="flex flex-wrap md:text-base text-sm font-normal font-Eina01-Regular text-neutralGrey leading-5">
                  Join the ARAD Ambassador Program and help make your community <br/> safer, stronger, and more connected.
                </p>
              </div>
                
              <form className="flex flex-col space-y-6 mt-6">
                  <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                          First Name
                        </label>
                        <input
                          className="py-4 px-3 md:w-72 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                          name="firstName"
                          type="text"
                          value={inputs.firstName || ""} 
                          onChange={handleChange}
                          required
                          placeholder="Enter your first name"
                        />
                        {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                          Last Name
                        </label>
                        <input
                          className="py-4 px-3 md:w-72 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                          name="lastName"
                          type="text"
                          value={inputs.lastName || ""} 
                          onChange={handleChange}
                          required
                          placeholder="Enter your last name"
                        />
                        {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                          Email address
                        </label>
                        <input
                          className="py-4 px-3 md:w-72 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                          name="email"
                          type="email"
                          value={inputs.email || ""} 
                          onChange={handleChange}
                          required
                          placeholder="Enter your Email address"
                        />
                        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                          Phone number (Whatsapp)
                        </label>
                        <input
                          className="py-4 px-3 md:w-72 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                          name="phoneNumber"
                          type="text"
                          value={inputs.phoneNumber || ""} 
                          onChange={handleChange}
                          required
                          placeholder="Enter your Phone number"
                        />
                        {errors.phoneNumber && <p className="text-red-500 text-xs">{errors.phoneNumber}</p>}
                    </div>
                  </div>

                  <div className="flex flex-col space-y-2">
                      <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                        Location
                      </label>
                      <input
                        className="py-4 px-3 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                        name="location"
                        type="text"
                        value={inputs.location || ""} 
                        onChange={handleChange}
                        required
                        placeholder="Enter your location"
                      />
                      {errors.location && <p className="text-red-500 text-xs">{errors.location}</p>}
                  </div>

                  <div className="flex flex-col space-y-2">
                      <label className="text-sm font-normal font-Eina01-Regular text-neutralGrey">
                        Why Do You Want to Become an ARAD Ambassador? (Optional)
                      </label>
                      <textarea
                        rows={3}
                        className="py-4 px-3 outline outline-darkGrey outline-1 focus:outline-none border-green focus:border-2 focus:border-slightRed rounded-lg placeholder:text-sm placeholder:font-normal placeholder:font-SF-Pro-Regular"
                        name="reason"
                        value={inputs.reason || ""} 
                        onChange={handleChange}
                        type="text"
                        placeholder="Share your motivation for joining this program (e.g., fostering safety, community growth)."
                      />
                  </div>

                  <TextButton
                    title={"Submit"}
                    onClick={handleSubmit}
                  />
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
