import React, { useState, useEffect } from "react";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import Bg from "../../assets/images/livestream.svg";
import JWTDecoder from "../../utils/utils";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { BsFillShareFill, BsFillHeartFill } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRoomDetails } from "../../redux/live/live.action";
import { getLiveRoomDetail } from "../../redux/api";
import SocketManager from "../../socket/socketManager";

function Livestream() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();
  const [, setMessages] = useState([]);
  const [userToken, setToken] = useState();

  const view = () => {
    navigate(`/dashboard/livestream/stream/${name}`);
  };
  useEffect(() => {
    SocketManager?.instance?.socket?.connect();

    return () => {
      SocketManager?.instance?.socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (name) {
      dispatch(getRoomDetails(name));
    }
    // eslint-disable-next-line
  }, [name]);

  const fetchLivestream = () => {
    getLiveRoomDetail(name)
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    fetchLivestream();
    // eslint-disable-next-line
  }, [name]);
  useEffect(() => {
    SocketManager.instance.emitJoinRoom({
      userName: userToken?.username,
      roomName: "roomName",
      user: userToken,
      roomId: name,
    });

    SocketManager.instance.listenSendMessage((data) => {
      setMessages((messages) => [data, ...messages]);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setToken(JWTDecoder.decode(localStorage.getItem("accessToken")));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardLayout>
      <div className="py-[1.5rem] pl-[3rem] flex bg-white items-center">
        <AiOutlineArrowLeft className="text-xl text-primary mr-[1rem] " />{" "}
        <NavLink to="/dashboard/livestream"> Livestreams</NavLink>
      </div>

      <div className="w-full h-full   backdrop-blur-md bg-white">
        <div className="bg-white h-[400px] cursor-pointer" onClick={view}>
          <img src={Bg} alt="bg" className="w-full h-full object-cover" />
        </div>
        <div className="  ">
          <div className="flex justify-between w-full py-[1rem] px-[.5rem]">
            <div className="flex  w-full">
              <div className="w-[40px] h-[40px] ml-[1rem] bg-black rounded-full ">
                {/* <img
                  src={people10}
                  alt="user"
                  className="w-full h-full bg-cover rounded-[100%]"
                /> */}
              </div>
              <div className="ml-[17px] ">
                <h5 className="text-[#27272E] font-bold text-[20px]">
                  Lorem ipsum dolor, sit amet consectetur adipisicing.
                </h5>
                <div className="flex">
                  <span className="text-[.7rem] mr-1 text-[#718096]">
                    Annaclaramm
                  </span>

                  <BsDot className="text-red-600" />
                  <span className="text-[.7rem] text-[#718096]">
                    Streamed 10mins agos
                  </span>
                </div>
              </div>
            </div>
            <div className="flex ">
              <div className="py-[.3rem] m bg-[#f2f4f7] flex items-center px-[2rem] rounded-[25px]">
                <BsFillHeartFill className="text-[.8rem] text-red-500  " />
                <span className="ml-4 text-[.8rem]">10k</span>{" "}
              </div>
              <div className="py-[.3rem] ml-[1rem] bg-[#f2f4f7] flex items-center px-[2rem] rounded-[25px]">
                <BsFillShareFill className="text-[.8rem] text-black  " />{" "}
                <span className="ml-4 text-[.8rem]">Share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white pt-[1rem]">
        <h4 className="ml-5">Comments (504)</h4>
        <div className="w-full h-full   backdrop-blur-md bg-white">
          <div className="  ">
            <div className=" py-[1rem] px-[.5rem]">
              <div className="flex  w-full">
                <div className="w-[40px] h-[40px] ml-[1rem] bg-black rounded-full ">
                  {/* <img
                  src={people10}
                  alt="user"
                  className="w-full h-full bg-cover rounded-[100%]"
                /> */}
                </div>
                <div className="ml-[17px]  -mt-2">
                  <div className="flex items-center">
                    <h5 className="text-[#27272E] mr-3 ">John Deo</h5>
                    <span className=" mt-1 text-[#718096]">
                      Aug 21, 10:30am
                    </span>
                  </div>
                  <div className="">
                    <p className="text-[1rem] mr-1 text-[#718096]">
                      Annaclaramm Annaclaramm Annaclaramm Annaclaramm
                      Annaclaramm Annaclaramm
                    </p>
                    <NavLink
                      to="/dashboard/livestream/stream/name"
                      className="text-[.rem] font-semibold mr-1 text-[#718096]"
                    >
                      Reply
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="flex  w-full mt-[1rem]">
                <div className="w-[40px] h-[40px] ml-[1rem] bg-black rounded-full ">
                  {/* <img
                  src={people10}
                  alt="user"
                  className="w-full h-full bg-cover rounded-[100%]"
                /> */}
                </div>
                <div className="ml-[17px]  -mt-2">
                  <div className="flex items-center">
                    <h5 className="text-[#27272E] mr-3 ">John Deo</h5>
                    <span className=" mt-1 text-[#718096]">
                      Aug 21, 10:30am
                    </span>
                  </div>
                  <div className="">
                    <p className="text-[1rem] mr-1 text-[#718096]">
                      Annaclaramm Annaclaramm Annaclaramm Annaclaramm
                      Annaclaramm Annaclaramm
                    </p>
                    <NavLink
                      to="/dashboard/livestream/stream/name"
                      className="text-[.rem]  mr-1 font-semibold text-[#718096]"
                    >
                      Reply
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Livestream;
