import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aradugbo_icon } from "../../constants/images";
import Logo from "../../assets/images/logo.png";

import { publicLink } from "../../constants/data";
import { NavLink, useNavigate } from "react-router-dom";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { FaRegPlusSquare } from "react-icons/fa";
import {
  FiLogOut,
  FiMessageSquare,
  FiMoon,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import SocketManager from "../../socket/socketManager";
import {
  logOut,
  setModalStatus,
  setSearchBar,
} from "../../redux/auth/auth.actions";
import { fetchAllNotification } from "../../redux/posts/posts.action";
import EditProfile from "../AccountComponent/EditProfile";
import { useDisclosure } from "@chakra-ui/react";
import ThemeToggle from "../ThemeButton/Toggle";

const DashboardMenu = ({ pathname, location }) => {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const { accessToken, user } = useSelector((state) => state.auth);
  const showSearchBar = useSelector(
    (state) => state.auth.leftSideBar?.showSearchBar
  );
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { isOpen, onClose } = useDisclosure();

  const handleDropDown = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    dispatch(logOut());
    navigate("/dashboard/home");
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const goTo = (path, name) => {
    if (name === "search" || name === "alert") {
      dispatch(setSearchBar(true, name));
      navigate(`/dashboard/${name}`);
    } else {
      dispatch(setSearchBar(false));

      navigate(path);
    }
  };

  const closeLeftSideBar = () => {
    dispatch(setSearchBar(false, null));
  };

  const handleModalUplaodAction = () => {
    closeLeftSideBar();
    goTo("/dashboard/home");
    dispatch(setModalStatus(6));
  };

  useEffect(() => {
    SocketManager?.instance?.emitListLiveStream({
      lat: location?.coords?.latitude,
      lon: location?.coords?.longitude,
    });
    SocketManager?.instance?.listenListLiveStream((data) => {
      setData(data);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchAllNotification());

    // eslint-disable-next-line
  }, []);

  const Menu = forwardRef(function Menu() {
    return (
      <ul
        ref={menuRef}
        tabIndex="0"
        className={`fixed z-[1000] bottom-[90px] x menu w-[200px]  py-2 mx-auto shadow ${
          darktheme ? "bg-black text-white" : "bg-[#fff] text-kdark3"
        }`}
      >
        <li
          className={`px-2 py-[.5rem] `}
          onClick={() => goTo("/dashboard/my-profile", "profile")}
        >
          <span className="flex  gap-[4px] hover:text-red-500 ">
            <FiUser className="w-6 h-4 " />
            <span className="mr-4">View Profile</span>
          </span>
        </li>
        <li
          className="px-2 py-[.5rem]"
          onClick={() => goTo("/dashboard/account", "account")}
        >
          <span className="flex  gap-[4px] hover:text-red-500">
            <FiSettings className="w-6 h-4 " />
            <p className="mr-4">Setting</p>
          </span>
        </li>
        <li
          className="px-2 py-[.5rem]"
          onClick={() => goTo("/dashboard/account", "account")}
        >
          <span className="flex gap-[4px] hover:text-red-500 ">
            <FiMessageSquare className="w-6 h-4 " />
            <p className="mr-4">Feedback and help</p>
          </span>
        </li>
        <li className="px-2 py-[.5rem]">
          <span className="flex  gap-[4px] hover:text-red-500 ">
            <FiMoon className="w-6 h-4 " />
            <p className="mr-4">Dark mode</p>

            <ThemeToggle />
          </span>
        </li>
        <li
          className="px-2 py-[.8rem] border-t-[#ebe9e9] border-t-[1px]"
          onClick={handleLogout}
        >
          <span className="flex hover:text-red-500  ">
            <FiLogOut className="w-6 h-4 " />
            <p className="mr-4">Logout</p>
          </span>
        </li>
      </ul>
    );
  });

  // Close the menu if a click happens outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // menuRef.current.focus();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`flex  flex-col justify-between h-full relative py-6 overflow-y-auto ${
          darktheme ? "bg-kdark3" : "bg-[#fff]"
        }`}
      >
        <div>
          <div className="flex justify-between items-center gap-2 p-3 mx-5 mt-[1.3rem] lg:mx-8">
            <a href="/dashboard/home" className="flex items-center">
              <span>
                <img
                  src={showSearchBar ? Logo : aradugbo_icon}
                  alt="Arad"
                  className="max-h-7"
                />
              </span>
            </a>
          </div>

          <div className="flex flex-col mt-8  pl-3 ml-4 lg:ml-4">
            {publicLink && (
              <>
                {publicLink?.map((item) => (
                  <div
                    key={item?.title}
                    // href={path}
                    onClick={() => goTo(item?.path, item.name)}
                    className={` py-2 mb-2  md:w-full flex items-center  hover:text-[#FC5059] hover:cursor-pointer   ${
                      darktheme
                        ? "bg-kdark3 text-[#888a8b]"
                        : "bg-[#fff] text-[#1C2024]"
                    }  ${
                      pathname?.includes(item?.path)
                        ? " text-[#FC5059]  border-r-2 border-primary"
                        : " text-[#60646C]"
                    } 
                    ${
                      item?.privateRoute && !accessToken
                        ? "hidden"
                        : "inline-flex"
                    }
                    `}
                  >
                    {item?.title === "Profile" ? (
                      <span>
                        <img
                          src={user?.imageUrl}
                          className="w-[2rem] h-[2rem] rounded-[50%]"
                          alt="user"
                        />
                      </span>
                    ) : (
                      <span className="stroke-[#FC5059]">{item?.icon}</span>
                    )}
                    {!showSearchBar && (
                      <>
                        <p
                          className={` hover:text-[#FC5059] text-[18px]  ml-[1.2rem]  ${
                            pathname?.includes(item?.path)
                              ? " text-[#FC5059] "
                              : "text-[#1C2024]"
                          } ${
                            darktheme ? " text-[#888a8b]" : " text-[#1C2024]"
                          } `}
                        >
                          {item?.title}
                        </p>
                        {item?.title === "Livestream" ? (
                          <span className="bg-red-400 text-white w-4 h-4 text-[.6rem] ml-2 items-center p-1 flex justify-center text-center rounded-full">
                            {data?.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                ))}
              </>
            )}
            {accessToken ? (
              <div
                className={`text-[white] py-[.5rem] flex items-center justify-center cursor-pointer mt-[1rem] bg-black border ${
                  showSearchBar ? "w-[30px] " : "w-[180px]"
                } rounded-md px-[.rem] py-[.4rem]`}
                onClick={handleModalUplaodAction}
              >
                <FaRegPlusSquare
                  className={`text-white ${showSearchBar && "w-[9px]"}`}
                />
                {!showSearchBar && (
                  <p className=" text-[white] text-center  ml-[.5rem] justify-center   items-center flex">
                    Upload
                  </p>
                )}
              </div>
            ) : (
              <div className="mt-[2rem]" onClick={() => handleLogin()}>
                <button className="border-[#98A2B3] border rounded-md px-[3rem] py-[.5rem]">
                  Login
                </button>
              </div>
            )}
          </div>
        </div>

        {accessToken ? (
          <div
            className="flex relative md:justify-between gap-2 mx-5 border-t border-[#efefef] pt-7 lg:mx-6 text- cursor-pointer dropdown dropdown-top"
            tabIndex="0"
          >
            <div className="flex gap-3 items-center">
              <span className="relative w-10 h-10 rounded-full bg-[#C4C4C4]">
                {user?.imageUrl ? (
                  <img
                    src={user?.imageUrl}
                    alt="name"
                    className="rounded-full"
                    placeholder="blur"
                    layout="fill"
                  />
                ) : (
                  ""
                )}
              </span>

              {!showSearchBar && (
                <div className={`${darktheme ? "text-white" : "text-kdark3"}`}>
                  <h2>
                    {user?.username
                      ? `${user?.username.slice(0, 15)}${
                          user?.username?.length > 15 ? "..." : ""
                        }`
                      : "Username"}
                  </h2>
                  <h2
                    className={`text-xs text-[#AAAAAA] ${
                      darktheme ? "text-white" : "text-kdark3"
                    }`}
                  >
                    {user?.email
                      ? `${user?.email?.slice(0, 14)}${
                          user?.email?.length > 14 ? "..." : ""
                        }`
                      : "Email"}
                  </h2>
                </div>
              )}
            </div>
            {!open && (
              <IoChevronDown
                className={`text-[14px] float-right ${
                  darktheme ? "text-white" : "text-kdark3"
                }`}
                onClick={handleDropDown}
                ref={menuRef}
              />
            )}
            {open && (
              <IoChevronUp
                className={`text-[14px]  float-right ${
                  darktheme ? "text-white" : "text-kdark3"
                }`}
                onClick={handleDropDown}
                ref={menuRef}
              />
            )}
            {open && <Menu />}
          </div>
        ) : (
          ""
        )}
      </div>
      {<EditProfile open={isOpen} type={"My"} onClose={onClose} />}
    </>
  );
};

export default DashboardMenu;
