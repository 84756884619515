import React from "react";
import { useNavigate } from "react-router-dom";

import { RiBroadcastLine } from "react-icons/ri";
function UserCard({ item }) {
  const navigate = useNavigate();

  const view = (roomId) => {
    navigate(`/dashboard/livestream`);
  };

  return (
    <div className="w-full gap-2 flex">
      {item?.map((stream, i) => (
        <div
          className="flexflex-col cursor-pointer"
          onClick={() => view(stream?.roomId)}
          key={i}
        >
          <div className="relative w-[64px] h-[64px] border-[1px] border-[red] rounded-full">
            <img
              className="w-[full] h-[ful] object-cover rounded-full"
              src={stream?.host?.imageUrl}
              alt={stream?.title}
            />
            {stream?.liveStatus === 1 ? (
              <div className="bg-red-500 text-center flex items-center text-white absolute  text-[.5rem] py-[.1rem] px-[rem] -bottom-1 w-full   rounded-[15px]">
                <div className="mx-auto text-center gap-1 flex items-center">
                  <RiBroadcastLine className="text-[14px] text-center  w-[15px] text-white" />
                  <p className="text-[14px] text-center ">Live</p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <p className=" overflow-hidden truncate w-[50%] text-center cursor-pointer">
            {stream?.host?.displayName || stream?.host?.username}
          </p> */}
        </div>
      ))}
    </div>
  );
}

export default UserCard;
