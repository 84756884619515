import React from "react";

const Commentcard = ({ name, date, text, image }) => {
  return (
    <div className="flex mb-2">
      <div className="w-[48px] h-[48px] bg-black mr-2 rounded-[50%]">
        {image && (
          <img src={image} alt="user" className="w-full h-full rounded-[50%]" />
        )}
      </div>
      <div className="w-[80%]">
        <div className="flex text-[12px] ">
          <h5 className="text-[#1c2024] capitalize">{name}</h5>
          <span className="text-[#718096]  text-[12px] ml-1">{date}</span>
        </div>
        <p className="text-[#60646c] capitalize text-[13px]">{text}</p>
      </div>
    </div>
  );
};

export default Commentcard;
